// alert
export const SET_ALERTS = 'SET_ALERTS';
export const REMOVE_ALERTS = 'REMOVE_ALERTS';

// titlebar
export const SET_TITLE = 'SET_LOADING';
export const REMOVE_TITLE = 'REMOVE_TITLE';

// auth
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERRORS = 'AUTH_ERRORS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const CLEAR_AUTH_ERRORS = 'CLEAR_AUTH_ERRORS';
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING';
export const REMOVE_AUTH_LOADING = 'REMOVE_AUTH_LOADING';

// users
export const GET_USERS = 'GET_USERS';
export const CLEAR_USERS = 'CLEAR_USERS';
export const GET_USER = 'GET_USER';
export const CLEAR_USER = 'CLEAR_USER';
export const ADD_USER = 'ADD_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const FILTER_USERS = 'FILTER_USERS';
export const CLEAR_FILTER_USERS = 'CLEAR_FILTER_USERS';
export const USERS_ERRORS = 'USERS_ERRORS';
export const CLEAR_USERS_ERRORS = 'CLEAR_USERS_ERRORS';
export const SET_USERS_LOADING = 'SET_USERS_LOADING';
export const EDIT_USER_PASSWORD = 'EDIT_USER_PASSWORD';

// artists
export const GET_ARTISTS = 'GET_ARTISTS';
export const CLEAR_ARTISTS = 'CLEAR_ARTISTS';
export const GET_ARTIST = 'GET_ARTIST';
export const CLEAR_ARTIST = 'CLEAR_ARTIST';
export const ADD_ARTIST = 'ADD_ARTIST';
export const UPDATE_ARTIST = 'UPDATE_ARTIST';
export const ARTISTS_ERRORS = 'ARTISTS_ERRORS';
export const CLEAR_ARTISTS_ERRORS = 'CLEAR_ARTISTS_ERRORS';
export const SET_ARTISTS_LOADING = 'SET_ARTISTS_LOADING';
export const GET_ART_DIRECTORS = 'GET_ART_DIRECTORS';
export const CLEAR_ART_DIRECTORS = 'CLEAR_ART_DIRECTORS';
export const SET_ART_DIRECTORS_LOADING = 'SET_ART_DIRECTORS_LOADING';
export const ORDER_ART_DIRECTORS = 'ORDER_ART_DIRECTORS';
export const GET_EVENT_ARTISTS = 'GET_EVENT_ARTISTS';
export const CLEAR_EVENT_ARTISTS = 'CLEAR_EVENT_ARTISTS';
export const SET_EVENT_ARTISTS_LOADING = 'SET_EVENT_ARTISTS_LOADING';
export const ORDER_EVENT_ARTISTS = 'ORDER_EVENT_ARTISTS';
export const DELETE_ARTIST = 'DELETE_ARTIST';
export const GET_ART_DIRECTORS_IN_ORDER = 'GET_ART_DIRECTORS_IN_ORDER';
export const GET_EVENT_ARTISTS_IN_ORDER = 'GET_EVENT_ARTISTS_IN_ORDER';

// events
export const GET_EVENTS = 'GET_EVENTS';
export const CLEAR_EVENTS = 'CLEAR_EVENTS';
export const GET_EVENT = 'GET_EVENT';
export const CLEAR_EVENT = 'CLEAR_EVENT';
export const ADD_EVENT = 'ADD_EVENT';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const EVENTS_ERRORS = 'EVENTS_ERRORS';
export const CLEAR_EVENTS_ERRORS = 'CLEAR_EVENTS_ERRORS';
export const SET_EVENTS_LOADING = 'SET_EVENTS_LOADING';
export const GET_PHASE_EVENTS = 'GET_PHASE_EVENTS';
export const CLEAR_PHASE_EVENTS = 'CLEAR_PHASE_EVENTS';
export const SET_PHASE_EVENTS_LOADING = 'SET_PHASE_EVENTS_LOADING';
export const DELETE_EVENT = 'DELETE_EVENT';

// phases
export const GET_PHASES = 'GET_PHASES';
export const CLEAR_PHASES = 'CLEAR_PHASES';
export const GET_PHASE = 'GET_PHASE';
export const CLEAR_PHASE = 'CLEAR_PHASE';
export const ADD_PHASE = 'ADD_PHASE';
export const UPDATE_PHASE = 'UPDATE_PHASE';
export const PHASES_ERRORS = 'PHASES_ERRORS';
export const CLEAR_PHASES_ERRORS = 'CLEAR_PHASES_ERRORS';
export const SET_PHASES_LOADING = 'SET_PHASES_LOADING';
export const DELETE_PHASE = 'DELETE_PHASE';

// activities
export const GET_ACTIVITIES = 'GET_ACTIVITIES';
export const CLEAR_ACTIVITIES = 'CLEAR_ACTIVITIES';
export const GET_ACTIVITY = 'GET_ACTIVITY';
export const CLEAR_ACTIVITY = 'CLEAR_ACTIVITY';
export const ADD_ACTIVITY = 'ADD_ACTIVITY';
export const UPDATE_ACTIVITY = 'UPDATE_ACTIVITY';
export const ACTIVITIES_ERRORS = 'ACTIVITIES_ERRORS';
export const CLEAR_ACTIVITIES_ERRORS = 'CLEAR_ACTIVITIES_ERRORS';
export const SET_ACTIVITIES_LOADING = 'SET_ACTIVITIES_LOADING';
export const DELETE_ACTIVITY = 'DELETE_ACTIVITY';

// newses
export const GET_NEWSES = 'GET_NEWSES';
export const CLEAR_NEWSES = 'CLEAR_NEWSES';
export const GET_NEWS = 'GET_NEWS';
export const CLEAR_NEWS = 'CLEAR_NEWS';
export const ADD_NEWS = 'ADD_NEWS';
export const UPDATE_NEWS = 'UPDATE_NEWS';
export const NEWSES_ERRORS = 'NEWSES_ERRORS';
export const CLEAR_NEWSES_ERRORS = 'CLEAR_NEWSES_ERRORS';
export const SET_NEWSES_LOADING = 'SET_NEWSES_LOADING';
// export const GET_NEWSES_IN_ORDER = 'GET_NEWSES_IN_ORDER';
// export const CLEAR_NEWSES_IN_ORDER = 'CLEAR_NEWSES_IN_ORDER';
// export const ORDER_NEWSES = 'ORDER_NEWSES';
// export const SET_NEWSES_IN_ORDER_LOADING = 'SET_NEWSES_IN_ORDER_LOADING';
export const DELETE_NEWS = 'DELETE_NEWS';

// news media items
export const GET_NEWS_MEDIA_ITEMS = 'GET_NEWS_MEDIA_ITEMS';
export const CLEAR_NEWS_MEDIA_ITEMS = 'CLEAR_NEWS_MEDIA_ITEMS';
export const GET_NEWS_MEDIA_ITEM = 'GET_NEWS_MEDIA_ITEM';
export const CLEAR_NEWS_MEDIA_ITEM = 'CLEAR_NEWS_MEDIA_ITEM';
export const ADD_NEWS_MEDIA_ITEM = 'ADD_NEWS_MEDIA_ITEM';
export const UPDATE_NEWS_MEDIA_ITEM = 'UPDATE_NEWS_MEDIA_ITEM';
export const NEWS_MEDIA_ITEMS_ERRORS = 'NEWS_MEDIA_ITEMS_ERRORS';
export const CLEAR_NEWS_MEDIA_ITEMS_ERRORS = 'CLEAR_NEWS_MEDIA_ITEMS_ERRORS';
export const SET_NEWS_MEDIA_ITEMS_LOADING = 'SET_NEWS_MEDIA_ITEMS_LOADING';
export const DELETE_NEWS_MEDIA_ITEM = 'DELETE_NEWS_MEDIA_ITEM';
export const GET_ACTIVITIES_FOR_SELECT = 'GET_ACTIVITIES_FOR_SELECT';
export const CLEAR_ACTIVITIES_FOR_SELECT = 'CLEAR_ACTIVITIES_FOR_SELECT';
export const SET_ACTIVITIES_FOR_SELECT_LOADING =
  'SET_ACTIVITIES_FOR_SELECT_LOADING';

// media
export const GET_MEDIA = 'GET_MEDIA';
export const CLEAR_MEDIA = 'CLEAR_MEDIA';
export const GET_MEDIUM = 'GET_MEDIUM';
export const CLEAR_MEDIUM = 'CLEAR_MEDIUM';
export const ADD_MEDIUM = 'ADD_MEDIUM';
export const UPDATE_MEDIUM = 'UPDATE_MEDIUM';
export const MEDIA_ERRORS = 'MEDIA_ERRORS';
export const CLEAR_MEDIA_ERRORS = 'CLEAR_MEDIA_ERRORS';
export const SET_MEDIA_LOADING = 'SET_MEDIA_LOADING';

// landing page
export const GET_LANDING_PAGE = 'GET_LANDING_PAGE';
export const CLEAR_LANDING_PAGE = 'CLEAR_LANDING_PAGE';
export const UPDATE_LANDING_PAGE = 'UPDATE_LANDING_PAGE';
export const LANDING_PAGE_ERRORS = 'LANDING_PAGE_ERRORS';
export const CLEAR_LANDING_PAGE_ERRORS = 'CLEARING_LANDING_PAGE_ERRORS';
export const SET_LANDING_PAGE_LOADING = 'SET_LANDING_PAGE_LOADING';

// global constants
export const GET_GLOBAL_CONSTANTS = 'GET_GLOBAL_CONSTANTS';
export const CLEAR_GLOBAL_CONSTANTS = 'CLEAR_GLOBAL_CONSTANTS';
export const UPDATE_GLOBAL_CONSTANTS = 'UPDATE_GLOBAL_CONSTANTS';
export const GLOBAL_CONSTANTS_ERRORS = 'GLOBAL_CONSTANTS_ERRORS';
export const CLEAR_GLOBAL_CONSTANTS_ERRORS = 'CLEARING_GLOBAL_CONSTANTS_ERRORS';
export const SET_GLOBAL_CONSTANTS_LOADING = 'SET_GLOBAL_CONSTANTS_LOADING';

// ticketing default
export const GET_TICKETING_DEFAULT = 'GET_TICKETING_DEFAULT';
export const CLEAR_TICKETING_DEFAULT = 'CLEAR_TICKETING_DEFAULT';
export const UPDATE_TICKETING_DEFAULT = 'UPDATE_TICKETING_DEFAULT';
export const TICKETING_DEFAULT_ERRORS = 'TICKETING_DEFAULT_ERRORS';
export const CLEAR_TICKETING_DEFAULT_ERRORS =
  'CLEARING_TICKETING_DEFAULT_ERRORS';
export const SET_TICKETING_DEFAULT_LOADING = 'SET_TICKETING_DEFAULT_LOADING';

// contacts
export const GET_CONTACTS = 'GET_CONTACTS';
export const CLEAR_CONTACTS = 'CLEAR_CONTACTS';
export const GET_CONTACT = 'GET_CONTACT';
export const CLEAR_CONTACT = 'CLEAR_CONTACT';
export const ADD_CONTACT = 'ADD_CONTACT';
export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const CONTACTS_ERRORS = 'CONTACTS_ERRORS';
export const CLEAR_CONTACTS_ERRORS = 'CLEAR_CONTACTS_ERRORS';
export const SET_CONTACTS_LOADING = 'SET_CONTACTS_LOADING';
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const EXPORT_CONTACTS = 'EXPORT_CONTACTS';
export const SET_CONTACTS_EXPORT_LOADING = 'SET_CONTACTS_EXPORT_LOADING';
export const IMPORT_CONTACTS = 'IMPORT_CONTACTS';
export const SET_CONTACTS_IMPORT_LOADING = 'SET_CONTACTS_IMPORT_LOADING';

// about
export const GET_ABOUT = 'GET_ABOUT';
export const CLEAR_ABOUT = 'CLEAR_ABOUT';
export const UPDATE_ABOUT = 'UPDATE_ABOUT';
export const ABOUT_ERRORS = 'ABOUT_ERRORS';
export const CLEAR_ABOUT_ERRORS = 'CLEARING_ABOUT_ERRORS';
export const SET_ABOUT_LOADING = 'SET_ABOUT_LOADING';

// newsletters
export const GET_NEWSLETTERS = 'GET_NEWSLETTERS';
export const CLEAR_NEWSLETTERS = 'CLEAR_NEWSLETTERS';
export const GET_NEWSLETTER = 'GET_NEWSLETTER';
export const CLEAR_NEWSLETTER = 'CLEAR_NEWSLETTER';
export const ADD_NEWSLETTER = 'ADD_NEWSLETTER';
export const UPDATE_NEWSLETTER = 'UPDATE_NEWSLETTER';
export const NEWSLETTERS_ERRORS = 'NEWSLETTERS_ERRORS';
export const CLEAR_NEWSLETTERS_ERRORS = 'CLEAR_NEWSLETTERS_ERRORS';
export const SET_NEWSLETTERS_LOADING = 'SET_NEWSLETTERS_LOADING';
export const SET_SENDER = 'SET_SENDER';
export const SEND_NEWSLETTER = 'SEND_NEWSLETTER';
export const DELETE_NEWSLETTER = 'DELETE_ACTIVITY';
// export const GET_NEWSLETTERS_IN_ORDER = 'GET_NEWSLETTERS_IN_ORDER';
// export const CLEAR_NEWSLETTERS_IN_ORDER = 'CLEAR_NEWSLETTERS_IN_ORDER';
// export const ORDER_NEWSLETTERS = 'ORDER_NEWSLETTERS';
// export const SET_NEWSLETTERS_IN_ORDER_LOADING =
//   'SET_NEWSLETTERS_IN_ORDER_LOADING';

// sendHistories
export const GET_SENDHISTORIES = 'GET_SENDHISTORIES';
export const CLEAR_SENDHISTORIES = 'CLEAR_SENDHISTORIES';
export const GET_SENDHISTORY = 'GET_SENDHISTORY';
export const CLEAR_SENDHISTORY = 'CLEAR_SENDHISTORY';
export const SENDHISTORIES_ERRORS = 'SENDHISTORIES_ERRORS';
export const CLEAR_SENDHISTORIES_ERRORS = 'CLEAR_SENDHISTORIES_ERRORS';
export const SET_SENDHISTORIES_LOADING = 'SET_SENDHISTORIES_LOADING';

// sender
export const GET_SENDER = 'GET_SENDER';
export const CLEAR_SENDER = 'CLEAR_SENDER';
export const UPDATE_SENDER = 'UPDATE_SENDER';
export const SENDER_ERRORS = 'SENDER_ERRORS';
export const CLEAR_SENDER_ERRORS = 'CLEARING_SENDER_ERRORS';
export const SET_SENDER_LOADING = 'SET_SENDER_LOADING';

// miscellaneous info
export const GET_MISCELLANEOUS_INFO = 'GET_MISCELLANEOUS_INFO';
export const CLEAR_MISCELLANEOUS_INFO = 'CLEAR_MISCELLANEOUS_INFO';
export const UPDATE_MISCELLANEOUS_INFO = 'UPDATE_MISCELLANEOUS_INFO';
export const MISCELLANEOUS_INFO_ERRORS = 'MISCELLANEOUS_INFO_ERRORS';
export const CLEAR_MISCELLANEOUS_INFO_ERRORS =
  'CLEARING_MISCELLANEOUS_INFO_ERRORS';
export const SET_MISCELLANEOUS_INFO_LOADING = 'SET_MISCELLANEOUS_INFO_LOADING';

// page meta miscellaneous
export const GET_PAGE_META_MISCELLANEOUS = 'GET_PAGE_META_MISCELLANEOUS';
export const CLEAR_PAGE_META_MISCELLANEOUS = 'CLEAR_PAGE_META_MISCELLANEOUS';
export const UPDATE_PAGE_META_MISCELLANEOUS = 'UPDATE_PAGE_META_MISCELLANEOUS';
export const PAGE_META_MISCELLANEOUS_ERRORS = 'PAGE_META_MISCELLANEOUS_ERRORS';
export const CLEAR_PAGE_META_MISCELLANEOUS_ERRORS =
  'CLEARING_PAGE_META_MISCELLANEOUS_ERRORS';
export const SET_PAGE_META_MISCELLANEOUS_LOADING =
  'SET_PAGE_META_MISCELLANEOUS_LOADING';
