export default {
  'Table.IndexColumnTitle': 'Idx',

  'HomePage.Title': 'Home Page',

  'Navbar.Title': '粵劇新秀演出系列',
  'Navbar.Login': 'Login',
  'Navbar.Logout': 'Logout',
  'Navbar.Greeting': '你好',
  'Navbar.PasswordEdit': 'Password Change',
  'Navbar.Users': 'Users',
  'Navbar.Artists': 'Artists',
  'Navbar.Events': 'Events',
  'Navbar.CommunityPerformances': 'Community Performances',
  'Navbar.Phases': 'Phases',
  'Navbar.LandingPage': 'Landing Page',
  'Navbar.GlobalConstants': 'Global Constants',
  'Navbar.About': 'About Page',
  'Navbar.MiscellaneousInfo': 'Miscellaneous Info',
  'Navbar.PageMetaMiscellaneous': 'Page Meta Miscellaneous',
  'Navbar.TicketingDefaultEdit': 'Ticketing Default',
  'Navbar.Contacts': 'Contacts',
  'Navbar.Newsletters': 'Newsletters',
  'Navbar.SenderEdit': 'Sender',
  'Navbar.Activities': 'Activities',
  'Navbar.Newses': 'News',
  'Navbar.NewsMediaItems': 'News Media Items',
  'Navbar.Testing': 'Testing Page',

  'SortableList.ItemRemoveButton.Tooltip': 'Remove',
  'SortableList.ItemToFirstButton.Tooltip': 'To first',
  'SortableList.ItemToLastButton.Tooltip': 'To last',

  'Login.Title': 'Account Login',
  'Login.FillInAllFieldsMessage': 'Please fill in all fields',
  'Login.EmailLabel': 'Email',
  'Login.PasswordLabel': 'Password',
  'Login.LoginButton': 'Login',

  'User.NameLabel': 'Name',
  'User.EmailLabel': 'Email',
  'User.PasswordLabel': 'Password',
  'User.RoleLabel': 'Role',
  'User.CreateDTLabel': 'Create DT',
  'User.LastModifyDTLabel': 'Last Modify DT',
  'User.LastModifyUserLabel': 'Last Modify User',
  'User.IsEnabledLabel': 'Is Enabled?',

  'Users.Title': 'Users',
  'Users.ForbiddenAccessMessage': 'You are forbidden to access user records.',

  'UserList.AddUserPrompt': 'Please add a user.',
  'UserList.AddUser': 'Add User',

  'UserEdit.BackToUserList': 'Back to User List',
  'UserEdit.AddUserTitle': 'Add User',
  'UserEdit.EditUserTitle': 'Edit User',
  'UserEdit.PasswordEdit': 'Password Change',
  'UserEdit.OldPasswordLabel': 'Old Password',
  'UserEdit.NewPasswordLabel': 'New Password',
  'UserEdit.ConfirmPasswordLabel': 'Confirm Password',
  'UserEdit.ChangePasswordLabel': 'Change Password',
  'UserEdit.CancelChangePasswordLabel': 'Cancel Change Password',
  'UserEdit.AddUserSubmit': 'Add User',
  'UserEdit.UpdateUserSubmit': 'Update User',
  'UserEdit.AddUserSuccessMessage': 'User Added',
  'UserEdit.UpdateUserSuccessMessage': 'User Updated',
  'UserEdit.ChangePasswordSuccessMessage': 'Password Updated',
  'UserEdit.ConfirmPasswordDoesNotMatchMessage':
    'Confirm password does not match',

  'UserFilter.FilterTextPlaceHolder': 'Filter Users by name...',

  'Artists.Title': 'Artists',

  'Artist.LabelLabel': 'Label',
  'Artist.OrderLabel': 'Order',
  'Artist.NameTcLabel': 'Name TC',
  'Artist.NameScLabel': 'Name SC',
  'Artist.NameEnLabel': 'Name EN',
  'Artist.TypeLabel': 'Type',
  'Artist.RoleLabel': 'Role',
  'Artist.DirectorRemarksTcLabel': 'Director Remarks TC',
  'Artist.DirectorRemarksScLabel': 'Director Remarks SC',
  'Artist.DirectorRemarksEnLabel': 'Director Remarks EN',
  'Artist.FeaturedImageLabel': 'Featured Image',
  'Artist.WithoutMaskImageLabel': 'Without Mask Image',
  'Artist.GalleryLabel': 'Gallery',
  'Artist.SoundLabel': 'Sound',
  'Artist.DescTcLabel': 'Desc TC',
  'Artist.DescScLabel': 'Desc SC',
  'Artist.DescEnLabel': 'Desc EN',
  'Artist.QnasLabel': "Q & A's",
  'Artist.CreateDTLabel': 'Create DT',
  'Artist.LastModifyDTLabel': 'Last Modify DT',
  'Artist.LastModifyUserLabel': 'Last Modify User',
  'Artist.IsEnabledLabel': 'Is Enabled?',

  'ArtistList.AddArtist': 'Add Artist',
  'ArtistList.FilterTextPlaceHolder': 'Filter Artists by name...',
  'ArtistList.FilterButton': 'Filter',
  'ArtistList.ClearFilterButton': 'Clear Filter',

  'ArtistList.ArtDirectorsOrder': 'Order Art Directors',
  'ArtistList.ArtistsOrder': 'Order Artists',

  'ArtistEdit.BackToArtistList': 'Back to Artist List',
  'ArtistEdit.AddArtistTitle': 'Add Artist',
  'ArtistEdit.EditArtistTitle': 'Edit Artist',
  'ArtistEdit.AddArtistSubmit': 'Add Artist',
  'ArtistEdit.UpdateArtistSubmit': 'Update Artist',
  'ArtistEdit.DeleteArtist': 'Delete Artist',
  'ArtistEdit.DeleteArtistSuccessMessage': 'Artist Deleted',
  'ArtistEdit.AddArtistSuccessMessage': 'Artist Added',
  'ArtistEdit.UpdateArtistSuccessMessage': 'Artist Updated',

  'ArtistEdit.Qna.QuestionTcPlaceholder': 'Question TC',
  'ArtistEdit.Qna.AnswerTcPlaceholder': 'Answer TC',
  'ArtistEdit.Qna.QuestionScPlaceholder': 'Question SC',
  'ArtistEdit.Qna.AnswerScPlaceholder': 'Answer SC',
  'ArtistEdit.Qna.QuestionEnPlaceholder': 'Question EN',
  'ArtistEdit.Qna.AnswerEnPlaceholder': 'Answer EN',

  'ArtistEdit.MediaRegionTitle': 'Media',
  'ArtistEdit.DescriptionRegionTitle': 'Description',
  'ArtistEdit.QnasRegionTitle': "Q & A's",

  'ArtDirectorsOrder.Title': 'Order Art Directors',
  'ArtDirectorsOrder.BackToArtistList': 'Back to Artist List',
  'ArtDirectorsOrder.Submit': 'Save',
  'ArtDirectorsOrder.OrderArtDirectorsSuccessMessage':
    'Art Directors Order Saved',

  'ArtistsOrder.Title': 'Order Artists',
  'ArtistsOrder.BackToArtistList': 'Back to Artist List',
  'ArtistsOrder.Submit': 'Save',
  'ArtistsOrder.OrderArtistsSuccessMessage': 'Artists Order Saved',

  'Events.Title': 'Events',
  'CommunityPerformances.Title': 'Community Performances',

  'Event.LabelLabel': 'Label',
  'Event.NameTcLabel': 'Name TC',
  'Event.NameScLabel': 'Name SC',
  'Event.NameEnLabel': 'Name EN',
  'Event.NameForLongDisplayTcLabel': 'Name for Long Display TC',
  'Event.NameForLongDisplayScLabel': 'Name for Long Display SC',
  'Event.NameForLongDisplayEnLabel': 'Name for Long Display EN',
  'Event.ArtDirectorsLabel': 'Art Directors',
  'Event.ShowsLabel': 'Shows',
  'Event.ScenaristsLabel': 'Scenarists',
  'Event.ScriptMastersLabel': 'Script Masters',
  'Event.DescHeadlineTcLabel': 'Desc Headline TC',
  'Event.DescHeadlineScLabel': 'Desc Headline SC',
  'Event.DescHeadlineEnLabel': 'Desc Headline EN',
  'Event.DescTcLabel': 'Desc TC',
  'Event.DescScLabel': 'Desc SC',
  'Event.DescEnLabel': 'Desc EN',
  'Event.RemarksTcLabel': 'Remarks TC',
  'Event.RemarksScLabel': 'Remarks SC',
  'Event.RemarksEnLabel': 'Remarks EN',
  'Event.FeaturedImageLabel': 'Featured Image',
  'Event.GalleryLabel': 'Gallery',
  'Event.ArtistsLabel': 'Cast',
  'Event.VenueTcLabel': 'Venue TC',
  'Event.VenueScLabel': 'Venue SC',
  'Event.VenueEnLabel': 'Venue EN',
  'Event.PricesLabel': 'Prices',
  'Event.PriceRemarksTcLabel': 'Price Remarks TC',
  'Event.PriceRemarksScLabel': 'Price Remarks SC',
  'Event.PriceRemarksEnLabel': 'Price Remarks EN',
  'Event.PhonesLabel': 'Phones',
  'Event.TicketUrlLabel': 'Ticket Url',
  'Event.ThemeColorLabel': 'Theme Color',
  'Event.CreateDTLabel': 'Create DT',
  'Event.LastModifyDTLabel': 'Last Modify DT',
  'Event.LastModifyUserLabel': 'Last Modify User',
  'Event.IsEnabledLabel': 'Is Enabled?',

  'EventList.AddEvent': 'Add Event',
  'EventList.FilterTextPlaceHolder': 'Filter Events by name...',
  'EventList.FilterButton': 'Filter',
  'EventList.ClearFilterButton': 'Clear Filter',

  'CommunityPerformanceList.AddCommunityPerformance':
    'Add Community Performance',
  'CommunityPerformanceList.FilterTextPlaceHolder':
    'Filter Community Performances...',

  'EventEdit.BackToEventList': 'Back to Event List',
  'EventEdit.AddEventTitle': 'Add Event',
  'EventEdit.EditEventTitle': 'Edit Event',
  'EventEdit.AddEventSubmit': 'Add Event',
  'EventEdit.UpdateEventSubmit': 'Update Event',
  'EventEdit.DeleteEvent': 'Delete Event',
  'EventEdit.AddEventSuccessMessage': 'Event Added',
  'EventEdit.UpdateEventSuccessMessage': 'Event Updated',
  'EventEdit.DeleteEventSuccessMessage': 'Event Deleted',

  'CommunityPerformanceEdit.BackToCommunityPerformanceList':
    'Back to Community Performance List',
  'CommunityPerformanceEdit.AddCommunityPerformanceTitle':
    'Add Community Performance',
  'CommunityPerformanceEdit.EditCommunityPerformanceTitle':
    'Edit Community Performance',
  'CommunityPerformanceEdit.AddCommunityPerformanceSubmit':
    'Add Community Performance',
  'CommunityPerformanceEdit.UpdateCommunityPerformanceSubmit':
    'Update Community Performance',
  'CommunityPerformanceEdit.DeleteCommunityPerformance':
    'Delete Community Performance',
  'CommunityPerformanceEdit.AddCommunityPerformanceSuccessMessage':
    'Community Performance Added',
  'CommunityPerformanceEdit.UpdateCommunityPerformanceSuccessMessage':
    'Community Performance Updated',
  'CommunityPerformanceEdit.DeleteCommunityPerformanceSuccessMessage':
    'Community Performance Deleted',

  'EventEdit.SelectArtDirectorsPlaceholder': 'Select Art Directors...',
  'EventEdit.Artist.RoleTcPlaceholder': 'Role TC',
  'EventEdit.Artist.RoleScPlaceholder': 'Role SC',
  'EventEdit.Artist.RoleEnPlaceholder': 'Role EN',
  'EventEdit.Artist.IsGuestArtistLabel': 'Guest?',
  'EventEdit.Artist.ArtistPlaceholder': 'Select Artist...',
  'EventEdit.Artist.GuestArtistNameTcPlaceholder': 'Guest Name TC',
  'EventEdit.Artist.GuestArtistNameScPlaceholder': 'Guest Name SC',
  'EventEdit.Artist.GuestArtistNameEnPlaceholder': 'Guest Name EN',
  'EventEdit.Artist.GuestArtistRemarksTcPlaceholder': 'Remarks TC',
  'EventEdit.Artist.GuestArtistRemarksScPlaceholder': 'Remarks SC',
  'EventEdit.Artist.GuestArtistRemarksEnPlaceholder': 'Remarks EN',
  'EventEdit.Artist.GuestArtistImageAdd': 'Guest Image',

  'EventEdit.Scenarist.NameTcPlaceholder': 'Name TC',
  'EventEdit.Scenarist.NameScPlaceholder': 'Name SC',
  'EventEdit.Scenarist.NameEnPlaceholder': 'Name EN',

  'EventEdit.ScriptMaster.NameTcPlaceholder': 'Name TC',
  'EventEdit.ScriptMaster.NameScPlaceholder': 'Name SC',
  'EventEdit.ScriptMaster.NameEnPlaceholder': 'Name EN',

  'EventEdit.Price.PriceTcPlaceholder': 'Price TC',
  'EventEdit.Price.PriceScPlaceholder': 'Price SC',
  'EventEdit.Price.PriceEnPlaceholder': 'Price EN',

  'EventEdit.Phone.LabelTcPlaceholder': 'Label TC',
  'EventEdit.Phone.LabelScPlaceholder': 'Label SC',
  'EventEdit.Phone.LabelEnPlaceholder': 'Label EN',
  'EventEdit.Phone.PhonePlaceholder': 'Phone',

  'EventEdit.Show.SelectDatePlaceholder': 'Click to select date',

  'EventEdit.TicketRegionTitle': 'Ticketing',
  'EventEdit.MediaRegionTitle': 'Media',
  'EventEdit.PeopleRegionTitle': 'People',
  'EventEdit.ShowsRegionTitle': 'Shows',
  'EventEdit.DescriptionRegionTitle': 'Description',

  'Phases.Title': 'Phases',

  'Phase.DerivedLabelLabel': 'Label',
  'Phase.YearLabel': 'Year',
  'Phase.PhaseNumberLabel': 'Phase Number',
  'Phase.ThemeColorLabel': 'Theme Color',
  'Phase.FromDateLabel': 'From Date',
  'Phase.ToDateLabel': 'To Date',
  'Phase.EventsLabel': 'Events',
  'Phase.DownloadNameTcLabel': 'Download Leaflet Name TC',
  'Phase.DownloadNameScLabel': 'Download Leaflet Name SC',
  'Phase.DownloadNameEnLabel': 'Download Leaflet Name EN',
  'Phase.DownloadMediumLabel': 'Download Leaflet',
  'Phase.TicketSaleRemarksTcLabel': 'Ticket Sale Remarks TC',
  'Phase.TicketSaleRemarksScLabel': 'Ticket Sale Remarks SC',
  'Phase.TicketSaleRemarksEnLabel': 'Ticket Sale Remarks EN',
  'Phase.CreateDTLabel': 'Create DT',
  'Phase.LastModifyDTLabel': 'Last Modify DT',
  'Phase.LastModifyUserLabel': 'Last Modify User',
  'Phase.IsEnabledLabel': 'Is Enabled?',

  'PhaseList.AddPhase': 'Add Phase',
  'PhaseList.FilterTextPlaceHolder': 'Filter Phases by year...',
  'PhaseList.FilterButton': 'Filter',
  'PhaseList.ClearFilterButton': 'Clear Filter',

  'PhaseEdit.BackToPhaseList': 'Back to Phase List',
  'PhaseEdit.AddPhaseTitle': 'Add Phase',
  'PhaseEdit.EditPhaseTitle': 'Edit Phase',
  'PhaseEdit.AddPhaseSubmit': 'Add Phase',
  'PhaseEdit.UpdatePhaseSubmit': 'Update Phase',
  'PhaseEdit.DeletePhase': 'Delete Phase',
  'PhaseEdit.AddPhaseSuccessMessage': 'Phase Added',
  'PhaseEdit.UpdatePhaseSuccessMessage': 'Phase Updated',
  'PhaseEdit.DeletePhaseSuccessMessage': 'Phase Deleted',

  'PhaseEdit.SelectEventsPlaceholder': 'Select Events...',
  'PhaseEdit.SelectFromDatePlaceholder': 'Click to select date',
  'PhaseEdit.SelectToDatePlaceholder': 'Click to select date',

  'LandingPage.Title': 'Landing Page',

  'LandingPage.LandingVideosLabel': 'Landing Videos',
  'LandingPage.FeaturedVideo1Label': 'Featured Video 1',
  'LandingPage.FeaturedVideo2Label': 'Featured Video 2',
  //'LandingPage.FeaturedArtistsLabel': 'Featured Artists',
  'LandingPage.FeaturedActivitiesLabel': 'Featured Activities',
  'LandingPage.LastModifyDTLabel': 'Last Modify DT',
  'LandingPage.LastModifyUserLabel': 'Last Modify User',

  'LandingPageEdit.EditLandingPageTitle': 'Edit Landing Page',
  'LandingPageEdit.UpdateLandingPageSubmit': 'Update Landing Page',
  'LandingPageEdit.UpdateLandingPageSuccessMessage': 'Landing Page Updated',
  'LandingPageEdit.SelectFeaturedArtistsPlaceholder':
    'Select Featured Artists...',
  'LandingPageEdit.SelectFeaturedActivitiesPlaceholder':
    'Select Featured Activities...',

  'LandingPageEdit.MediaRegionTitle': 'Media',

  'GlobalConstants.Title': 'Global Constants',

  'GlobalConstants.LatestShowTcLabel': 'Latest Show TC',
  'GlobalConstants.LatestShowScLabel': 'Latest Show SC',
  'GlobalConstants.LatestShowEnLabel': 'Latest Show EN',
  'GlobalConstants.LatestShowIcon1TcLabel': 'Latest Show Icon 1 TC',
  'GlobalConstants.LatestShowIcon1ScLabel': 'Latest Show Icon 1 SC',
  'GlobalConstants.LatestShowIcon1EnLabel': 'Latest Show Icon 1 EN',
  'GlobalConstants.LatestShowIcon2TcLabel': 'Latest Show Icon 2 TC',
  'GlobalConstants.LatestShowIcon2ScLabel': 'Latest Show Icon 2 SC',
  'GlobalConstants.LatestShowIcon2EnLabel': 'Latest Show Icon 2 EN',
  'GlobalConstants.ScheduleOfShowTcLabel': 'Schedule Of Show TC',
  'GlobalConstants.ScheduleOfShowScLabel': 'Schedule Of Show SC',
  'GlobalConstants.ScheduleOfShowEnLabel': 'Schedule Of Show EN',
  'GlobalConstants.TeamsTcLabel': 'Teams TC',
  'GlobalConstants.TeamsScLabel': 'Teams SC',
  'GlobalConstants.TeamsEnLabel': 'Teams EN',
  'GlobalConstants.ArtDirectorTcLabel': 'Art Director TC',
  'GlobalConstants.ArtDirectorScLabel': 'Art Director SC',
  'GlobalConstants.ArtDirectorEnLabel': 'Art Director EN',
  'GlobalConstants.ActorTcLabel': 'Actor TC',
  'GlobalConstants.ActorScLabel': 'Actor SC',
  'GlobalConstants.ActorEnLabel': 'Actor EN',
  'GlobalConstants.ArtistTcLabel': 'Artist TC',
  'GlobalConstants.ArtistScLabel': 'Artist SC',
  'GlobalConstants.ArtistEnLabel': 'Artist EN',
  'GlobalConstants.DetailsOfShowTcLabel': 'Details Of Show TC',
  'GlobalConstants.DetailsOfShowScLabel': 'Details Of Show SC',
  'GlobalConstants.DetailsOfShowEnLabel': 'Details Of Show EN',
  'GlobalConstants.ShowTcLabel': 'Show TC',
  'GlobalConstants.ShowScLabel': 'Show SC',
  'GlobalConstants.ShowEnLabel': 'Show EN',
  'GlobalConstants.AllShowTcLabel': 'All Show TC',
  'GlobalConstants.AllShowScLabel': 'All Show SC',
  'GlobalConstants.AllShowEnLabel': 'All Show EN',
  'GlobalConstants.ActivitiesTcLabel': 'Activities TC',
  'GlobalConstants.ActivitiesScLabel': 'Activities SC',
  'GlobalConstants.ActivitiesEnLabel': 'Activities EN',
  'GlobalConstants.DownloadPDFTcLabel': 'Download PDF TC',
  'GlobalConstants.DownloadPDFScLabel': 'Download PDF SC',
  'GlobalConstants.DownloadPDFEnLabel': 'Download PDF EN',
  'GlobalConstants.OurActorsTcLabel': 'Our Actors TC',
  'GlobalConstants.OurActorsScLabel': 'Our Actors SC',
  'GlobalConstants.OurActorsEnLabel': 'Our Actors EN',
  'GlobalConstants.YmtTheaterTcLabel': 'YMT Theater TC',
  'GlobalConstants.YmtTheaterScLabel': 'YMT Theater SC',
  'GlobalConstants.YmtTheaterEnLabel': 'YMT Theater EN',
  'GlobalConstants.FollowUsTcLabel': 'Follow Us TC',
  'GlobalConstants.FollowUsScLabel': 'Follow Us SC',
  'GlobalConstants.FollowUsEnLabel': 'Follow Us EN',
  'GlobalConstants.AllTcLabel': 'All TC',
  'GlobalConstants.AllScLabel': 'All SC',
  'GlobalConstants.AllEnLabel': 'All EN',
  'GlobalConstants.BoyTcLabel': 'Boy TC',
  'GlobalConstants.BoyScLabel': 'Boy SC',
  'GlobalConstants.BoyEnLabel': 'Boy EN',
  'GlobalConstants.GirlTcLabel': 'Girl TC',
  'GlobalConstants.GirlScLabel': 'Girl SC',
  'GlobalConstants.GirlEnLabel': 'Girl EN',
  'GlobalConstants.InheritTcLabel': 'Inherit TC',
  'GlobalConstants.InheritScLabel': 'Inherit SC',
  'GlobalConstants.InheritEnLabel': 'Inherit EN',
  'GlobalConstants.ShareTcLabel': 'Share TC',
  'GlobalConstants.ShareScLabel': 'Share SC',
  'GlobalConstants.ShareEnLabel': 'Share EN',
  'GlobalConstants.RelatedShowTcLabel': 'Related Show TC',
  'GlobalConstants.RelatedShowScLabel': 'Related Show SC',
  'GlobalConstants.RelatedShowEnLabel': 'Related Show EN',
  'GlobalConstants.RelatedArtistsTcLabel': 'Related Artists TC',
  'GlobalConstants.RelatedArtistsScLabel': 'Related Artists SC',
  'GlobalConstants.RelatedArtistsEnLabel': 'Related Artists EN',
  'GlobalConstants.RelatedDramaTcLabel': 'Related Drama TC',
  'GlobalConstants.RelatedDramaScLabel': 'Related Drama SC',
  'GlobalConstants.RelatedDramaEnLabel': 'Related Drama EN',
  'GlobalConstants.AllShowsTcLabel': 'All Shows TC',
  'GlobalConstants.AllShowsScLabel': 'All Shows SC',
  'GlobalConstants.AllShowsEnLabel': 'All Shows EN',
  'GlobalConstants.MoreTcLabel': 'More TC',
  'GlobalConstants.MoreScLabel': 'More SC',
  'GlobalConstants.MoreEnLabel': 'More EN',
  'GlobalConstants.ScenaristTcLabel': 'Scenarist TC',
  'GlobalConstants.ScenaristScLabel': 'Scenarist SC',
  'GlobalConstants.ScenaristEnLabel': 'Scenarist EN',
  'GlobalConstants.IntroductionTcLabel': 'Introduction TC',
  'GlobalConstants.IntroductionScLabel': 'Introduction SC',
  'GlobalConstants.IntroductionEnLabel': 'Introduction EN',
  'GlobalConstants.BuyNowTcLabel': 'Buy Now TC',
  'GlobalConstants.BuyNowScLabel': 'Buy Now SC',
  'GlobalConstants.BuyNowEnLabel': 'Buy Now EN',
  'GlobalConstants.ParticipatingTcLabel': 'Participating TC',
  'GlobalConstants.ParticipatingScLabel': 'Participating SC',
  'GlobalConstants.ParticipatingEnLabel': 'Participating EN',
  'GlobalConstants.RoleTcLabel': 'Role TC',
  'GlobalConstants.RoleScLabel': 'Role SC',
  'GlobalConstants.RoleEnLabel': 'Role EN',
  'GlobalConstants.StudentShowTcLabel': 'Student Show TC',
  'GlobalConstants.StudentShowScLabel': 'Student Show SC',
  'GlobalConstants.StudentShowEnLabel': 'Student Show EN',
  'GlobalConstants.NextScheduleTcLabel': 'Next Schedule TC',
  'GlobalConstants.NextScheduleScLabel': 'Next Schedule SC',
  'GlobalConstants.NextScheduleEnLabel': 'Next Schedule EN',
  'GlobalConstants.LeaveContactTcLabel': 'Leave Contact TC',
  'GlobalConstants.LeaveContactScLabel': 'Leave Contact SC',
  'GlobalConstants.LeaveContactEnLabel': 'Leave Contact EN',
  'GlobalConstants.PublicShowTcLabel': 'Public Show TC',
  'GlobalConstants.PublicShowScLabel': 'Public Show SC',
  'GlobalConstants.PublicShowEnLabel': 'Public Show EN',
  'GlobalConstants.ProgramOfShowTcLabel': 'Program Of Show TC',
  'GlobalConstants.ProgramOfShowScLabel': 'Program Of Show SC',
  'GlobalConstants.ProgramOfShowEnLabel': 'Program Of Show EN',
  'GlobalConstants.Total1TcLabel': 'Total 1 TC',
  'GlobalConstants.Total1ScLabel': 'Total 1 SC',
  'GlobalConstants.Total1EnLabel': 'Total 1 EN',
  'GlobalConstants.Total2TcLabel': 'Total 2 TC',
  'GlobalConstants.Total2ScLabel': 'Total 2 SC',
  'GlobalConstants.Total2EnLabel': 'Total 2 EN',
  'GlobalConstants.AboutTcLabel': 'About TC',
  'GlobalConstants.AboutScLabel': 'About SC',
  'GlobalConstants.AboutEnLabel': 'About EN',
  'GlobalConstants.MapTcLabel': 'Map TC',
  'GlobalConstants.MapScLabel': 'Map SC',
  'GlobalConstants.MapEnLabel': 'Map EN',
  'GlobalConstants.TrafficTcLabel': 'Traffic TC',
  'GlobalConstants.TrafficScLabel': 'Traffic SC',
  'GlobalConstants.TrafficEnLabel': 'Traffic EN',
  'GlobalConstants.ContactTcLabel': 'Contact TC',
  'GlobalConstants.ContactScLabel': 'Contact SC',
  'GlobalConstants.ContactEnLabel': 'Contact EN',
  'GlobalConstants.WebsiteTcLabel': 'Website TC',
  'GlobalConstants.WebsiteScLabel': 'Website SC',
  'GlobalConstants.WebsiteEnLabel': 'Website EN',
  'GlobalConstants.ContactInfoTcLabel': 'Contact Info TC',
  'GlobalConstants.ContactInfoScLabel': 'Contact Info SC',
  'GlobalConstants.ContactInfoEnLabel': 'Contact Info EN',
  'GlobalConstants.TelTcLabel': 'Tel TC',
  'GlobalConstants.TelScLabel': 'Tel SC',
  'GlobalConstants.TelEnLabel': 'Tel EN',
  'GlobalConstants.FaxTcLabel': 'Fax TC',
  'GlobalConstants.FaxScLabel': 'Fax SC',
  'GlobalConstants.FaxEnLabel': 'Fax EN',
  'GlobalConstants.EmailTcLabel': 'Email TC',
  'GlobalConstants.EmailScLabel': 'Email SC',
  'GlobalConstants.EmailEnLabel': 'Email EN',
  'GlobalConstants.ResearchAndEducationTcLabel': 'Research and Education TC',
  'GlobalConstants.ResearchAndEducationScLabel': 'Research and Education SC',
  'GlobalConstants.ResearchAndEducationEnLabel': 'Research and Education EN',
  'GlobalConstants.GuidedTalkTcLabel': 'Guided Talk TC',
  'GlobalConstants.GuidedTalkScLabel': 'Guided Talk SC',
  'GlobalConstants.GuidedTalkEnLabel': 'Guided Talk EN',
  'GlobalConstants.YouthProgrammeTcLabel': 'Youth Programme TC',
  'GlobalConstants.YouthProgrammeScLabel': 'Youth Programme SC',
  'GlobalConstants.YouthProgrammeEnLabel': 'Youth Programme EN',
  'GlobalConstants.CantoneseOperaKnowledgeTcLabel':
    'Cantonese Opera Knowledge TC',
  'GlobalConstants.CantoneseOperaKnowledgeScLabel':
    'Cantonese Opera Knowledge SC',
  'GlobalConstants.CantoneseOperaKnowledgeEnLabel':
    'Cantonese Opera Knowledge EN',
  'GlobalConstants.CommunityPerformanceTcLabel':
    'Community Performance TC',
  'GlobalConstants.CommunityPerformanceScLabel':
    'Community Performance SC',
  'GlobalConstants.CommunityPerformanceEnLabel':
    'Community Performance EN',
  // 'GlobalConstants.CollegeShowTcLabel': 'College Show TC',
  // 'GlobalConstants.CollegeShowScLabel': 'College Show SC',
  // 'GlobalConstants.CollegeShowEnLabel': 'College Show EN',
  'GlobalConstants.ExhibitionTcLabel': 'Exhibition TC',
  'GlobalConstants.ExhibitionScLabel': 'Exhibition SC',
  'GlobalConstants.ExhibitionEnLabel': 'Exhibition EN',
  'GlobalConstants.DetailsTcLabel': 'Details TC',
  'GlobalConstants.DetailsScLabel': 'Details SC',
  'GlobalConstants.DetailsEnLabel': 'Details EN',
  'GlobalConstants.DateOfShowTcLabel': 'Date Of Show TC',
  'GlobalConstants.DateOfShowScLabel': 'Date Of Show SC',
  'GlobalConstants.DateOfShowEnLabel': 'Date Of Show EN',
  'GlobalConstants.LocationTcLabel': 'Location TC',
  'GlobalConstants.LocationScLabel': 'Location SC',
  'GlobalConstants.LocationEnLabel': 'Location EN',
  'GlobalConstants.NewsTcLabel': 'News TC',
  'GlobalConstants.NewsScLabel': 'News SC',
  'GlobalConstants.NewsEnLabel': 'News EN',
  'GlobalConstants.RelatedNewsTcLabel': 'Related News TC',
  'GlobalConstants.RelatedNewsScLabel': 'Related News SC',
  'GlobalConstants.RelatedNewsEnLabel': 'Related News EN',
  'GlobalConstants.TicketInfoTcLabel': 'Ticket Info TC',
  'GlobalConstants.TicketInfoScLabel': 'Ticket Info SC',
  'GlobalConstants.TicketInfoEnLabel': 'Ticket Info EN',
  'GlobalConstants.VenueTcLabel': 'Venue TC',
  'GlobalConstants.VenueScLabel': 'Venue SC',
  'GlobalConstants.VenueEnLabel': 'Venue EN',
  'GlobalConstants.TicketPriceTcLabel': 'Ticket Price TC',
  'GlobalConstants.TicketPriceScLabel': 'Ticket Price SC',
  'GlobalConstants.TicketPriceEnLabel': 'Ticket Price EN',
  'GlobalConstants.TicketWebsiteTcLabel': 'Ticket Website TC',
  'GlobalConstants.TicketWebsiteScLabel': 'Ticket Website SC',
  'GlobalConstants.TicketWebsiteEnLabel': 'Ticket Website EN',
  'GlobalConstants.BarwoTcLabel': 'Barwo TC',
  'GlobalConstants.BarwoScLabel': 'Barwo SC',
  'GlobalConstants.BarwoEnLabel': 'Barwo EN',
  'GlobalConstants.PlanTcLabel': 'Plan TC',
  'GlobalConstants.PlanScLabel': 'Plan SC',
  'GlobalConstants.PlanEnLabel': 'Plan EN',
  'GlobalConstants.AdminsTcLabel': 'Admins TC',
  'GlobalConstants.AdminsScLabel': 'Admins SC',
  'GlobalConstants.AdminsEnLabel': 'Admins EN',
  'GlobalConstants.ProductionPersonsTcLabel': 'Production Persons TC',
  'GlobalConstants.ProductionPersonsScLabel': 'Production Persons SC',
  'GlobalConstants.ProductionPersonsEnLabel': 'Production Persons EN',
  'GlobalConstants.OrganizerTcLabel': 'Organizer TC',
  'GlobalConstants.OrganizerScLabel': 'Organizer SC',
  'GlobalConstants.OrganizerEnLabel': 'Organizer EN',
  'GlobalConstants.SponsorTcLabel': 'Sponsor TC',
  'GlobalConstants.SponsorScLabel': 'Sponsor SC',
  'GlobalConstants.SponsorEnLabel': 'Sponsor EN',
  'GlobalConstants.SearchTcLabel': 'Search TC',
  'GlobalConstants.SearchScLabel': 'Search SC',
  'GlobalConstants.SearchEnLabel': 'Search EN',
  'GlobalConstants.ActivityTcLabel': 'Activity TC',
  'GlobalConstants.ActivityScLabel': 'Activity SC',
  'GlobalConstants.ActivityEnLabel': 'Activity EN',
  'GlobalConstants.EventTcLabel': 'Event TC',
  'GlobalConstants.EventScLabel': 'Event SC',
  'GlobalConstants.EventEnLabel': 'Event EN',
  'GlobalConstants.SPECIAL_NOTICETcLabel': 'SPECIAL NOTICE TC',
  'GlobalConstants.SPECIAL_NOTICEScLabel': 'SPECIAL NOTICE SC',
  'GlobalConstants.SPECIAL_NOTICEEnLabel': 'SPECIAL NOTICE EN',
  'GlobalConstants.PRESS_RELEASETcLabel': 'PRESS RELEASE TC',
  'GlobalConstants.PRESS_RELEASEScLabel': 'PRESS RELEASE SC',
  'GlobalConstants.PRESS_RELEASEEnLabel': 'PRESS RELEASE EN',
  'GlobalConstants.INTERVIEWTcLabel': 'INTERVIEW TC',
  'GlobalConstants.INTERVIEWScLabel': 'INTERVIEW SC',
  'GlobalConstants.INTERVIEWEnLabel': 'INTERVIEW EN',
  'GlobalConstants.NewsMediaTcLabel': 'News Media TC',
  'GlobalConstants.NewsMediaScLabel': 'News Media SC',
  'GlobalConstants.NewsMediaEnLabel': 'News Media EN',
  'GlobalConstants.NewsletterTcLabel': 'Newsletter TC',
  'GlobalConstants.NewsletterScLabel': 'Newsletter SC',
  'GlobalConstants.NewsletterEnLabel': 'Newsletter EN',
  'GlobalConstants.EMAIL_SUCCESSTcLabel': 'EMAIL SUCCESS TC',
  'GlobalConstants.EMAIL_SUCCESSScLabel': 'EMAIL SUCCESS SC',
  'GlobalConstants.EMAIL_SUCCESSEnLabel': 'EMAIL SUCCESS EN',
  'GlobalConstants.EMAIL_ADDRESS_INVALIDTcLabel': 'EMAIL ADDRESS INVALID TC',
  'GlobalConstants.EMAIL_ADDRESS_INVALIDScLabel': 'EMAIL ADDRESS INVALID SC',
  'GlobalConstants.EMAIL_ADDRESS_INVALIDEnLabel': 'EMAIL ADDRESS INVALID EN',
  'GlobalConstants.EMAIL_ADDRESS_ALREADY_EXISTSTcLabel':
    'EMAIL ADDRESS ALREADY EXISTS TC',
  'GlobalConstants.EMAIL_ADDRESS_ALREADY_EXISTSScLabel':
    'EMAIL ADDRESS ALREADY EXISTS SC',
  'GlobalConstants.EMAIL_ADDRESS_ALREADY_EXISTSEnLabel':
    'EMAIL ADDRESS ALREADY EXISTS EN',
  'GlobalConstants.SubscribeMsgTcLabel': 'Subscribe Message TC',
  'GlobalConstants.SubscribeMsgScLabel': 'Subscribe Message SC',
  'GlobalConstants.SubscribeMsgEnLabel': 'Subscribe Message EN',
  'GlobalConstants.ContactusTcLabel': 'Contact Us TC',
  'GlobalConstants.ContactusScLabel': 'Contact Us SC',
  'GlobalConstants.ContactusEnLabel': 'Contact Us EN',
  'GlobalConstants.PastEventsTcLabel': 'Past Events TC',
  'GlobalConstants.PastEventsScLabel': 'Past Events SC',
  'GlobalConstants.PastEventsEnLabel': 'Past Events EN',
  'GlobalConstants.TermsAndConditionsTcLabel': 'Terms and Conditions TC',
  'GlobalConstants.TermsAndConditionsScLabel': 'Terms and Conditions SC',
  'GlobalConstants.TermsAndConditionsEnLabel': 'Terms and Conditions EN',
  'GlobalConstants.CopyrightTcLabel': 'Copyright TC',
  'GlobalConstants.CopyrightScLabel': 'Copyright SC',
  'GlobalConstants.CopyrightEnLabel': 'Copyright EN',
  'GlobalConstants.ArtistInfoTcLabel': 'Artist Info TC',
  'GlobalConstants.ArtistInfoScLabel': 'Artist Info SC',
  'GlobalConstants.ArtistInfoEnLabel': 'Artist Info EN',
  // 'GlobalConstants.CommunityPerformanceIndicatorTcLabel':
  //   'Community Performance Indicator TC',
  // 'GlobalConstants.CommunityPerformanceIndicatorScLabel':
  //   'Community Performance Indicator SC',
  // 'GlobalConstants.CommunityPerformanceIndicatorEnLabel':
  //   'Community Performance Indicator EN',
  'GlobalConstants.OtherShowTcLabel': 'Other Shows TC',
  'GlobalConstants.OtherShowScLabel': 'Other Shows SC',
  'GlobalConstants.OtherShowEnLabel': 'Other Shows EN',
  'GlobalConstants.SoundTcLabel': 'Artist Sound TC',
  'GlobalConstants.SoundScLabel': 'Artist Sound SC',
  'GlobalConstants.SoundEnLabel': 'Artist Sound EN',
  'GlobalConstants.SearchingTcLabel': 'Searching TC',
  'GlobalConstants.SearchingScLabel': 'Searching SC',
  'GlobalConstants.SearchingEnLabel': 'Searching EN',
  'GlobalConstants.NotfoundTcLabel': 'Search Not Found TC',
  'GlobalConstants.NotfoundScLabel': 'Search Not Found SC',
  'GlobalConstants.NotfoundEnLabel': 'Search Not Found EN',
  'GlobalConstants.Found1TcLabel': 'Search Found 1 TC',
  'GlobalConstants.Found1ScLabel': 'Search Found 1 SC',
  'GlobalConstants.Found1EnLabel': 'Search Found 1 EN',
  'GlobalConstants.Found2TcLabel': 'Search Found 2 TC',
  'GlobalConstants.Found2ScLabel': 'Search Found 2 SC',
  'GlobalConstants.Found2EnLabel': 'Search Found 2 EN',
  'GlobalConstants.GalleryOfShowTcLabel': 'Gallery Of Show TC',
  'GlobalConstants.GalleryOfShowScLabel': 'Gallery Of Show SC',
  'GlobalConstants.GalleryOfShowEnLabel': 'Gallery Of Show EN',
  'GlobalConstants.ExhibtionImagesTcLabel': 'Exhibition Images TC',
  'GlobalConstants.ExhibtionImagesScLabel': 'Exhibition Images SC',
  'GlobalConstants.ExhibtionImagesEnLabel': 'Exhibition Images EN',
  'GlobalConstants.Page404MessageTcLabel': 'Page 404 Message TC',
  'GlobalConstants.Page404MessageScLabel': 'Page 404 Message SC',
  'GlobalConstants.Page404MessageEnLabel': 'Page 404 Message EN',
  'GlobalConstants.MediaTcLabel': 'Media TC',
  'GlobalConstants.MediaScLabel': 'Media SC',
  'GlobalConstants.MediaEnLabel': 'Media EN',
  'GlobalConstants.PrivacypoliciesTcLabel': 'Privacy Policies TC',
  'GlobalConstants.PrivacypoliciesScLabel': 'Privacy Policies SC',
  'GlobalConstants.PrivacypoliciesEnLabel': 'Privacy Policies EN',
  'GlobalConstants.DateTcLabel': 'Date TC',
  'GlobalConstants.DateScLabel': 'Date SC',
  'GlobalConstants.DateEnLabel': 'Date EN',
  'GlobalConstants.ScriptArrangementTcLabel': 'Script Master TC',
  'GlobalConstants.ScriptArrangementScLabel': 'Script Master SC',
  'GlobalConstants.ScriptArrangementEnLabel': 'Script Master EN',
  'GlobalConstants.ViewOurTeamTcLabel': 'View All Artists TC',
  'GlobalConstants.ViewOurTeamScLabel': 'View All Artists SC',
  'GlobalConstants.ViewOurTeamEnLabel': 'View All Artists EN',
  'GlobalConstants.PhotosTcLabel': 'News Media Photos TC',
  'GlobalConstants.PhotosScLabel': 'News Media Photos SC',
  'GlobalConstants.PhotosEnLabel': 'News Media Photos EN',
  'GlobalConstants.VideosTcLabel': 'News Media Videos TC',
  'GlobalConstants.VideosScLabel': 'News Media Videos SC',
  'GlobalConstants.VideosEnLabel': 'News Media Videos EN',

  'GlobalConstants.LastModifyDTLabel': 'Last Modify DT',
  'GlobalConstants.LastModifyUserLabel': 'Last Modify User',

  'GlobalConstantsEdit.EditGlobalConstantsTitle': 'Edit Global Constants',
  'GlobalConstantsEdit.UpdateGlobalConstantsSubmit': 'Update Global Constants',
  'GlobalConstantsEdit.UpdateGlobalConstantsSuccessMessage':
    'Global Constants Updated',

  'TicketingDefault.Title': 'Ticketing Default',

  'TicketingDefault.VenueTcLabel': 'Venue TC',
  'TicketingDefault.VenueScLabel': 'Venue SC',
  'TicketingDefault.VenueEnLabel': 'Venue EN',
  'TicketingDefault.PriceRemarksTcLabel': 'Price Remarks TC',
  'TicketingDefault.PriceRemarksScLabel': 'Price Remarks SC',
  'TicketingDefault.PriceRemarksEnLabel': 'Price Remarks EN',
  'TicketingDefault.ticketUrlLabel': 'Ticket Url',

  'TicketingDefault.LastModifyDTLabel': 'Last Modify DT',
  'TicketingDefault.LastModifyUserLabel': 'Last Modify User',

  'TicketingDefaultEdit.EditTicketingDefaultEditTitle':
    'Edit Ticketing Default',
  'TicketingDefaultEdit.EditSubmit': 'Update Ticketing Default',
  'TicketingDefaultEdit.UpdateTicketingDefaultEditSuccessMessage':
    'Ticketing Default Updated',

  'Contacts.Title': 'Contacts',

  'Contact.EmailAddressLabel': 'Email Address',
  'Contact.NameLabel': 'Name',
  'Contact.TypeLabel': 'Type',
  'Contact.GroupsLabel': 'Groups',
  'Contact.LanguageLabel': 'Language',
  'Contact.LastModifyDTLabel': 'Last Modify DT',
  'Contact.LastModifyUserLabel': 'Last Modify User',
  'Contact.IsEnabledLabel': 'Is Enabled?',

  'ContactList.AddContact': 'Add Contact',
  'ContactList.FilterTextPlaceHolder': 'Filter Contacts by name...',
  'ContactList.FilterButton': 'Filter',
  'ContactList.ClearFilterButton': 'Clear Filter',
  'ContactList.ExportButton': 'Export',
  'ContactList.ImportButton': 'Import',
  'ContactList.ImportSuccessMessage': 'Contacts Imported',

  'ContactEdit.BackToContactList': 'Back to Contact List',
  'ContactEdit.AddContactTitle': 'Add Contact',
  'ContactEdit.EditContactTitle': 'Edit Contact',
  'ContactEdit.AddContactSubmit': 'Add Contact',
  'ContactEdit.UpdateContactSubmit': 'Update Contact',
  'ContactEdit.DeleteContact': 'Delete Contact',
  'ContactEdit.AddContactSuccessMessage': 'Contact Added',
  'ContactEdit.UpdateContactSuccessMessage': 'Contact Updated',
  'ContactEdit.DeleteContactSuccessMessage': 'Contact Deleted',
  'ContactEdit.SelectGroupsPlaceholder': ' Select Groups',

  'About.Title': 'About Page',

  'About.BarwoRegionLabel': 'Barwo',
  'About.BarwoDescTcLabel': 'Description TC',
  'About.BarwoDescScLabel': 'Description SC',
  'About.BarwoDescEnLabel': 'Description EN',
  'About.PlanRegionLabel': 'Plan',
  'About.PlanDescTcLabel': 'Description TC',
  'About.PlanDescScLabel': 'Description SC',
  'About.PlanDescEnLabel': 'Description EN',
  'About.PlanGalleryLabel': 'Gallery',
  'About.TheaterBasicInfoRegionLabel': 'Theater: Basic Info',
  'About.TheaterLocationNameTcLabel': 'Location TC',
  'About.TheaterLocationNameScLabel': 'Location SC',
  'About.TheaterLocationNameEnLabel': 'Location EN',
  'About.TheaterLocationHrefTcLabel': 'Google Map Link TC',
  'About.TheaterLocationHrefScLabel': 'Google Map Link SC',
  'About.TheaterLocationHrefEnLabel': 'Google Map Link EN',
  'About.TheaterLocationDesc1TcLabel': 'Desc 1 TC',
  'About.TheaterLocationDesc1ScLabel': 'Desc 1 SC',
  'About.TheaterLocationDesc1EnLabel': 'Desc 1 EN',
  'About.TheaterLocationDesc2TcLabel': 'Desc 2 TC',
  'About.TheaterLocationDesc2ScLabel': 'Desc 2 SC',
  'About.TheaterLocationDesc2EnLabel': 'Desc 2 EN',
  'About.TheaterImageLabel': 'Image',
  // 'About.TheaterTrafficAndContactRegionLabel': 'Theater: Transport', //'Theater: Transport & Contact',
  // 'About.TheaterTrafficTcLabel': 'Traffic TC',
  // 'About.TheaterTrafficScLabel': 'Traffic SC',
  // 'About.TheaterTrafficEnLabel': 'Traffic EN',
  // 'About.ContactWebsiteLabel': 'Website',
  // 'About.ContactTelLabel': 'Tel',
  // 'About.ContactFaxLabel': 'Fax',
  // 'About.ContactEmailLabel': 'Email',
  'About.AdminsRegionLabel': 'Admin Team',
  'About.AdminsLabel': 'Admin Team',
  'About.ProductionPersonsRegionLabel': 'Production Team',
  'About.ProductionPersonsLabel': 'Production Team',

  'About.LastModifyDTLabel': 'Last Modify DT',
  'About.LastModifyUserLabel': 'Last Modify User',

  'AboutEdit.EditAboutTitle': 'Edit About',
  'AboutEdit.UpdateAboutSubmit': 'Update About',
  'AboutEdit.UpdateAboutSuccessMessage': 'About Updated',

  'AboutEdit.StaffPerson.TitleTcPlaceholder': 'Title TC',
  'AboutEdit.StaffPerson.NameTcPlaceholder': 'Name TC',
  'AboutEdit.StaffPerson.TitleScPlaceholder': 'Title SC',
  'AboutEdit.StaffPerson.NameScPlaceholder': 'Name SC',
  'AboutEdit.StaffPerson.TitleEnPlaceholder': 'Title EN',
  'AboutEdit.StaffPerson.NameEnPlaceholder': 'Name EN',

  'MiscellaneousInfo.Title': 'Miscellaneous Info',

  'MiscellaneousInfo.LandingPopupRegionLabel': 'Landing Page Pop-up',
  'MiscellaneousInfo.IsShowLandingPopupLabel': 'Show Pop-up?',
  'MiscellaneousInfo.LandingPopupMessageTcLabel': 'Pop-up Message TC',
  'MiscellaneousInfo.LandingPopupMessageScLabel': 'Pop-up Message SC',
  'MiscellaneousInfo.LandingPopupMessageEnLabel': 'Pop-up Message EN',
  'MiscellaneousInfo.ContactRegionLabel': 'Contact Us',
  'MiscellaneousInfo.ContactAddressTcLabel': 'Address TC',
  'MiscellaneousInfo.ContactAddressScLabel': 'Address SC',
  'MiscellaneousInfo.ContactAddressEnLabel': 'Address EN',
  'MiscellaneousInfo.ContactTelLabel': 'Tel',
  'MiscellaneousInfo.ContactFaxLabel': 'Fax',
  'MiscellaneousInfo.ContactEmailLabel': 'Email',
  'MiscellaneousInfo.FooterRegionLabel': 'Footer',
  'MiscellaneousInfo.FooterOrganizerLogosLabel': 'Organizer Logos',
  'MiscellaneousInfo.FooterSponsorLogosLabel': 'Sponsor Logos',
  'MiscellaneousInfo.FacebookLinkLabel': 'Facebook Link',
  'MiscellaneousInfo.YoutubeLink': 'Youtube Link',
  'MiscellaneousInfo.InstagramLink': 'Instagram Link',
  //'MiscellaneousInfo.WechatLink': 'Wechat Link',
  'MiscellaneousInfo.TermsAndConditionsRegionLabel': 'Terms And Conditions',
  'MiscellaneousInfo.TermsAndConditionsTitleTcLabel': 'Title TC',
  'MiscellaneousInfo.TermsAndConditionsTitleScLabel': 'Title SC',
  'MiscellaneousInfo.TermsAndConditionsTitleEnLabel': 'Title EN',
  'MiscellaneousInfo.TermsAndConditionsDescTcLabel': 'Description TC',
  'MiscellaneousInfo.TermsAndConditionsDescScLabel': 'Description SC',
  'MiscellaneousInfo.TermsAndConditionsDescEnLabel': 'Description EN',
  'MiscellaneousInfo.PrivacyPolicyRegionLabel': 'Privacy Policy',
  'MiscellaneousInfo.PrivacyPolicyTitleTcLabel': 'Title TC',
  'MiscellaneousInfo.PrivacyPolicyTitleScLabel': 'Title SC',
  'MiscellaneousInfo.PrivacyPolicyTitleEnLabel': 'Title EN',
  'MiscellaneousInfo.PrivacyPolicyDescTcLabel': 'Description TC',
  'MiscellaneousInfo.PrivacyPolicyDescScLabel': 'Description SC',
  'MiscellaneousInfo.PrivacyPolicyDescEnLabel': 'Description EN',
  'MiscellaneousInfo.RecruitmentRegionLabel': 'Recruitment',
  'MiscellaneousInfo.RecruitmentTitleTcLabel': 'Title TC',
  'MiscellaneousInfo.RecruitmentTitleScLabel': 'Title SC',
  'MiscellaneousInfo.RecruitmentTitleEnLabel': 'Title EN',
  'MiscellaneousInfo.RecruitmentDescTcLabel': 'Description TC',
  'MiscellaneousInfo.RecruitmentDescScLabel': 'Description SC',
  'MiscellaneousInfo.RecruitmentDescEnLabel': 'Description EN',

  'MiscellaneousInfo.LastModifyDTLabel': 'Last Modify DT',
  'MiscellaneousInfo.LastModifyUserLabel': 'Last Modify User',

  'MiscellaneousInfoEdit.EditMiscellaneousInfoTitle': 'Edit Miscellaneous Info',
  'MiscellaneousInfoEdit.UpdateMiscellaneousInfoSubmit':
    'Update Miscellaneous Info',
  'MiscellaneousInfoEdit.UpdateMiscellaneousInfoSuccessMessage':
    'Miscellaneous Info Updated',

  'PageMetaMiscellaneous.Title': 'Page Meta Miscellaneous',

  'PageMetaMiscellaneous.LandingPageMetaLabel': 'Landing Page Meta',
  'PageMetaMiscellaneous.AboutMetaLabel': 'About Meta',
  'PageMetaMiscellaneous.ArtistListMetaLabel': 'Artist List Meta',
  'PageMetaMiscellaneous.EventListMetaLabel': 'Event List Meta',
  'PageMetaMiscellaneous.ActivityListMetaLabel': 'Activity List Meta',
  'PageMetaMiscellaneous.NewsListMetaLabel': 'News List Meta',
  'PageMetaMiscellaneous.MediaListMetaLabel': 'Media List Meta',
  'PageMetaMiscellaneous.RecruitmentMetaLabel': 'Recruitment Meta',

  'PageMetaMiscellaneous.LastModifyDTLabel': 'Last Modify DT',
  'PageMetaMiscellaneous.LastModifyUserLabel': 'Last Modify User',

  'PageMetaMiscellaneousEdit.EditPageMetaMiscellaneousTitle':
    'Edit Page Meta Miscellaneous',
  'PageMetaMiscellaneousEdit.UpdatePageMetaMiscellaneousSubmit':
    'Update Page Meta Miscellaneous',
  'PageMetaMiscellaneousEdit.UpdatePageMetaMiscellaneousSuccessMessage':
    'Page Meta Miscellaneous Updated',

  'Newsletters.Title': 'Newsletters',

  'Newsletter.LabelLabel': 'Label',
  //'Newsletter.OrderLabel': 'Order',
  'Newsletter.FromDateLabel': 'From Date',
  'Newsletter.TitleTcLabel': 'Title TC',
  'Newsletter.TitleScLabel': 'Title SC',
  'Newsletter.TitleEnLabel': 'Title EN',
  'Newsletter.MessageTcLabel': 'Message TC',
  'Newsletter.MessageScLabel': 'Message SC',
  'Newsletter.MessageEnLabel': 'Message EN',
  'Newsletter.FeaturedImageLabel': 'Featured Image',
  'Newsletter.LastModifyDTLabel': 'Last Modify DT',
  'Newsletter.LastModifyUserLabel': 'Last Modify User',
  'Newsletter.IsEnabledLabel': 'Is Enabled?',

  'NewsletterList.AddNewsletter': 'Add Newsletter',
  //'NewsletterList.NewslettersOrder': 'Order Newsletters',
  'NewsletterList.SendHistoryList': 'Send History List',
  'NewsletterList.FilterTextPlaceHolder': 'Filter Newsletters by name...',
  'NewsletterList.FilterButton': 'Filter',
  'NewsletterList.ClearFilterButton': 'Clear Filter',

  'NewsletterEdit.BackToNewsletterList': 'Back to Newsletter List',
  'NewsletterEdit.AddNewsletterTitle': 'Add Newsletter',
  'NewsletterEdit.EditNewsletterTitle': 'Edit Newsletter',
  'NewsletterEdit.AddNewsletterSubmit': 'Add Newsletter',
  'NewsletterEdit.UpdateNewsletterSubmit': 'Update Newsletter',
  'NewsletterEdit.DeleteNewsletter': 'Delete Newsletter',
  'NewsletterEdit.SendNewsletterSubmit': 'Send Newsletter',
  'NewsletterEdit.AddNewsletterSuccessMessage': 'Newsletter Added',
  'NewsletterEdit.UpdateNewsletterSuccessMessage': 'Newsletter Updated',
  'NewsletterEdit.SendNewsletterSuccessMessage': 'Newsletter Sent',
  'NewsletterEdit.SendNewsletterFailureMessage':
    'There was an error when sending emails. Please contact support.',
  'NewsletterEdit.DeleteNewsletterSuccessMessage': 'Newsletter Deleted',

  'NewsletterEdit.SelectFromDatePlaceholder': 'Click to select date',

  'NewsletterPreview.Title': 'Newsletter Preview',
  'NewsletterPreview.TcLabel': 'Traditional Chinese',
  'NewsletterPreview.ScLabel': 'Simpified Chinese',
  'NewsletterPreview.EnLabel': 'English',
  'NewsletterPreview.SubjectLabel': 'Subject: ',
  'NewsletterPreview.FromLabel': 'From: ',
  'NewsletterPreview.ToLabel': 'To: ',

  'NewslettersOrder.Title': 'Order Newsletters',
  'NewslettersOrder.BackToNewsletterList': 'Back to Newsletter List',
  'NewslettersOrder.Submit': 'Save',
  'NewslettersOrder.OrderNewslettersSuccessMessage': 'Newsletters Order Saved',

  'SendHistories.Title': 'Send History',

  'SendHistory.LabelLabel': 'Label',
  'SendHistory.RecipientsLabel': 'Recipients',
  'SendHistory.TitleTcLabel': 'Title TC',
  'SendHistory.TitleScLabel': 'Title SC',
  'SendHistory.TitleEnLabel': 'Title EN',
  'SendHistory.MessageTcLabel': 'Message TC',
  'SendHistory.MessageScLabel': 'Message SC',
  'SendHistory.MessageEnLabel': 'Message EN',
  'SendHistory.SendDTLabel': 'Send DT',
  'SendHistory.SenderLabel': 'Sender',
  'SendHistory.IsEnabledLabel': 'Is Enabled?',

  'SendHistoryList.SendHistoriesList': 'Send History List',
  'SendHistoryList.FilterTextPlaceHolder': 'Filter Send Historys by name...',
  'SendHistoryList.FilterButton': 'Filter',
  'SendHistoryList.ClearFilterButton': 'Clear Filter',

  'SendHistoryView.BackToSendHistoryList': 'Back to Send History List',
  'SendHistoryView.ViewSendHistory': 'Send History View',

  'Sender.Title': 'Sender',

  'Sender.EmailAddressLabel': 'Email Address',
  'Sender.NameTcLabel': 'Name TC',
  'Sender.NameScLabel': 'Name SC',
  'Sender.NameEnLabel': 'Name EN',
  'Sender.LastModifyDTLabel': 'Last Modify DT',
  'Sender.LastModifyUserLabel': 'Last Modify User',

  'SenderEdit.EditSenderTitle': 'Edit Sender',
  'SenderEdit.UpdateSenderSubmit': 'Update Sender',
  'SenderEdit.UpdateSenderSuccessMessage': 'Sender Updated',

  'Activities.Title': 'Activities',

  'Activity.LabelLabel': 'Label',
  'Activity.NameTcLabel': 'Name TC',
  'Activity.NameScLabel': 'Name SC',
  'Activity.NameEnLabel': 'Name EN',
  'Activity.NameForLongDisplayTcLabel': 'Name for Long Display TC',
  'Activity.NameForLongDisplayScLabel': 'Name for Long Display SC',
  'Activity.NameForLongDisplayEnLabel': 'Name for Long Display EN',
  'Activity.TypeLabel': 'Type',
  'Activity.FromDateLabel': 'From Date',
  'Activity.ToDateLabel': 'To Date',
  'Activity.LocationTcLabel': 'Location TC',
  'Activity.LocationScLabel': 'Location SC',
  'Activity.LocationEnLabel': 'Location EN',
  'Activity.DescTcLabel': 'Desc TC',
  'Activity.DescScLabel': 'Desc SC',
  'Activity.DescEnLabel': 'Desc EN',
  'Activity.FeaturedImageLabel': 'Featured Image',
  'Activity.GalleryLabel': 'Gallery',
  'Activity.VideoLinksLabel': 'Video Links',
  'Activity.DownloadNameTcLabel': 'Download Name TC',
  'Activity.DownloadNameScLabel': 'Download Name SC',
  'Activity.DownloadNameEnLabel': 'Download Name EN',
  'Activity.DownloadTypeLabel': 'Download Type',
  'Activity.DownloadMediumLabel': 'Download Medium',
  'Activity.DownloadUrlTcLabel': 'Download URL TC',
  'Activity.DownloadUrlScLabel': 'Download URL SC',
  'Activity.DownloadUrlEnLabel': 'Download URL EN',
  'Activity.CreateDTLabel': 'Create DT',
  'Activity.LastModifyDTLabel': 'Last Modify DT',
  'Activity.LastModifyUserLabel': 'Last Modify User',
  'Activity.IsEnabledLabel': 'Is Enabled?',

  'ActivityList.AddActivity': 'Add Activity',
  'ActivityList.FilterTextPlaceHolder': 'Filter Activities by name...',
  'ActivityList.FilterButton': 'Filter',
  'ActivityList.ClearFilterButton': 'Clear Filter',

  'ActivityEdit.BackToActivityList': 'Back to Activity List',
  'ActivityEdit.AddActivityTitle': 'Add Activity',
  'ActivityEdit.EditActivityTitle': 'Edit Activity',
  'ActivityEdit.AddActivitySubmit': 'Add Activity',
  'ActivityEdit.UpdateActivitySubmit': 'Update Activity',
  'ActivityEdit.DeleteActivity': 'Delete Activity',
  'ActivityEdit.AddActivitySuccessMessage': 'Activity Added',
  'ActivityEdit.UpdateActivitySuccessMessage': 'Activity Updated',
  'ActivityEdit.DeleteActivitySuccessMessage': 'Activity Deleted',

  'ActivityEdit.SelectFromDatePlaceholder': 'Click to select date',
  'ActivityEdit.SelectToDatePlaceholder': 'Click to select date',

  'ActivityEdit.MediaRegionTitle': 'Media',
  'ActivityEdit.LocationRegionTitle': 'Location',
  'ActivityEdit.DescriptionRegionTitle': 'Description',

  'Newses.Title': 'News',

  'News.LabelLabel': 'Label',
  'News.NameTcLabel': 'Name TC',
  'News.NameScLabel': 'Name SC',
  'News.NameEnLabel': 'Name EN',
  //'News.OrderLabel': 'Order',
  'News.TypeLabel': 'Type',
  'News.FromDateLabel': 'From Date',
  'News.DescTcLabel': 'Desc TC',
  'News.DescScLabel': 'Desc SC',
  'News.DescEnLabel': 'Desc EN',
  'News.FeaturedImageLabel': 'Featured Image',
  'News.DownloadNameTcLabel': 'Download Name TC',
  'News.DownloadNameScLabel': 'Download Name SC',
  'News.DownloadNameEnLabel': 'Download Name EN',
  'News.DownloadTypeLabel': 'Download Type',
  'News.DownloadMediumLabel': 'Download Medium',
  'News.DownloadUrlTcLabel': 'Download URL TC',
  'News.DownloadUrlScLabel': 'Download URL SC',
  'News.DownloadUrlEnLabel': 'Download URL EN',
  'News.CreateDTLabel': 'Create DT',
  'News.LastModifyDTLabel': 'Last Modify DT',
  'News.LastModifyUserLabel': 'Last Modify User',
  'News.IsEnabledLabel': 'Is Enabled?',

  'NewsList.AddNews': 'Add News',
  'NewsList.FilterTextPlaceHolder': 'Filter Activities by name...',
  'NewsList.FilterButton': 'Filter',
  'NewsList.ClearFilterButton': 'Clear Filter',

  //'NewsList.NewsesOrder': 'Order News',

  'NewsEdit.BackToNewsList': 'Back to News List',
  'NewsEdit.AddNewsTitle': 'Add News',
  'NewsEdit.EditNewsTitle': 'Edit News',
  'NewsEdit.AddNewsSubmit': 'Add News',
  'NewsEdit.UpdateNewsSubmit': 'Update News',
  'NewsEdit.DeleteNews': 'Delete News',
  'NewsEdit.AddNewsSuccessMessage': 'News Added',
  'NewsEdit.UpdateNewsSuccessMessage': 'News Updated',
  'NewsEdit.DeleteNewsSuccessMessage': 'News Deleted',

  'NewsEdit.MediaRegionTitle': 'Media',
  'NewsEdit.DescriptionRegionTitle': 'Description',

  'NewsEdit.SelectFromDatePlaceholder': 'Click to select date',

  'NewsesOrder.Title': 'Order News',
  'NewsesOrder.BackToNewsList': 'Back to News List',
  'NewsesOrder.Submit': 'Save',
  'NewsesOrder.OrderNewsesSuccessMessage': 'News Order Saved',

  'NewsMediaItems.Title': 'News Media Items',

  'NewsMediaItem.LabelLabel': 'Label',
  'NewsMediaItem.NameTcLabel': 'Name TC',
  'NewsMediaItem.NameScLabel': 'Name SC',
  'NewsMediaItem.NameEnLabel': 'Name EN',
  'NewsMediaItem.TypeLabel': 'Type',
  'NewsMediaItem.FromDateLabel': 'From Date',
  'NewsMediaItem.DescTcLabel': 'Desc TC',
  'NewsMediaItem.DescScLabel': 'Desc SC',
  'NewsMediaItem.DescEnLabel': 'Desc EN',
  'NewsMediaItem.ThumbnailLabel': 'Thumbnail',
  'NewsMediaItem.GalleryLabel': 'Gallery',
  'NewsMediaItem.VideoLinksLabel': 'Video Links',
  'NewsMediaItem.CreateDTLabel': 'Create DT',
  'NewsMediaItem.LastModifyDTLabel': 'Last Modify DT',
  'NewsMediaItem.LastModifyUserLabel': 'Last Modify User',
  'NewsMediaItem.IsEnabledLabel': 'Is Enabled?',

  'NewsMediaItemList.AddNewsMediaItem': 'Add News Media Item',
  'NewsMediaItemList.FilterTextPlaceHolder':
    'Filter News Media Items by name...',
  'NewsMediaItemList.FilterButton': 'Filter',
  'NewsMediaItemList.ClearFilterButton': 'Clear Filter',

  'NewsMediaItemEdit.BackToNewsMediaItemList': 'Back to News Media Item List',
  'NewsMediaItemEdit.AddNewsMediaItemTitle': 'Add News Media Item',
  'NewsMediaItemEdit.EditNewsMediaItemTitle': 'Edit News Media Item',
  'NewsMediaItemEdit.AddNewsMediaItemSubmit': 'Add News Media Item',
  'NewsMediaItemEdit.UpdateNewsMediaItemSubmit': 'Update News Media Item',
  'NewsMediaItemEdit.DeleteNewsMediaItem': 'Delete News Media Item',
  'NewsMediaItemEdit.DeleteNewsMediaItemSuccessMessage':
    'News Media Item Deleted',
  'NewsMediaItemEdit.AddNewsMediaItemSuccessMessage': 'News Media Item Added',
  'NewsMediaItemEdit.UpdateNewsMediaItemSuccessMessage':
    'News Media Item Updated',

  'NewsMediaItemEdit.SelectFromDatePlaceholder': 'Click to select date',

  'NewsMediaItemEdit.MediaRegionTitle': 'Media',
  'NewsMediaItemEdit.DescriptionRegionTitle': 'Description',

  'PageMeta.Title': 'Page Meta Tags',
  'PageMeta.TitleTcLabel': 'Title TC',
  'PageMeta.TitleScLabel': 'Title SC',
  'PageMeta.TitleEnLabel': 'Title EN',
  'PageMeta.DescriptionTcLabel': 'Description TC',
  'PageMeta.DescriptionScLabel': 'Description SC',
  'PageMeta.DescriptionEnLabel': 'Description EN',
  'PageMeta.OgSiteNameTcLabel': 'OG Site Name TC',
  'PageMeta.OgSiteNameScLabel': 'OG Site Name SC',
  'PageMeta.OgSiteNameEnLabel': 'OG Site Name EN',
  'PageMeta.OgTitleTcLabel': 'OG Title TC',
  'PageMeta.OgTitleScLabel': 'OG Title SC',
  'PageMeta.OgTitleEnLabel': 'OG Title EN',
  'PageMeta.OgDescriptionTcLabel': 'OG Description TC',
  'PageMeta.OgDescriptionScLabel': 'OG Description SC',
  'PageMeta.OgDescriptionEnLabel': 'OG Description EN',
  'PageMeta.OgImageLabel': 'OG Image',
  'PageMeta.OgImageAltTcLabel': 'OG Image Alt TC',
  'PageMeta.OgImageAltScLabel': 'OG Image Alt SC',
  'PageMeta.OgImageAltEnLabel': 'OG Image Alt EN',
  'PageMeta.FacebookAppIdLabel': 'Facebook App ID',

  'DeleteWithConfirmButton.ConfirmTitle': 'Confirm to submit',
  'DeleteWithConfirmButton.ConfirmMessage': 'Are you sure to delete?',
  'DeleteWithConfirmButton.ConfirmYesLabel': 'Yes',
  'DeleteWithConfirmButton.ConfirmNoLabel': 'No',

  'SendWithConfirmButton.ConfirmTitle': 'Confirm to submit',
  'SendWithConfirmButton.ConfirmMessage':
    'Which group(s) do you want to send the email to? If you want to send to everyone, please leave it unchecked.',
  'SendWithConfirmButton.ConfirmYesLabel': 'Send',
  'SendWithConfirmButton.ConfirmNoLabel': 'Cancel',

  'LabelColorPickerPair.OpenButton': 'Open Color Picker',

  'FileManager.Title': 'File Manager',
  'FileManager.SelectFile': 'Select File',
  'FileManager.DeleteFile': 'Delete File',
  'FileManager.UploadButton': 'Upload',
  'FileManager.DragToUploadHint': 'Drop File To Upload',
  'FileManager.NumberOfFilesSelectedSuffix': 'files selected',
  'FileManager.MediaPreview': 'Media Preview',
  'FileManager.MediaUrl': 'Media URL',

  'FileUpload.TitleForSingleUpload': 'File Manager',
  'FileUpload.TitleForMultipleUpload': 'File Manager (Multiple)',

  'SimpleFileUpload.UrlPlaceholder': 'File URL...',
  'SimpleFileUpload.RemoveFile': 'Remove',
  'SimpleFileUpload.PickFile': 'Pick'
};
