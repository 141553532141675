export default {
  SERVER_ERROR: {
    type: 'SERVER_ERROR',
    msg: 'SERVER_ERROR'
  },
  CLIENT_ERROR: {
    type: 'CLIENT_ERROR',
    msg: 'CLIENT_ERROR'
  }
};
